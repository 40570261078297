import { makeStyles } from '@material-ui/core';
import LogoNavbar from '../../assets/logo-navbar.png';
import React from 'react';

const useStyles = makeStyles({
  logoFont: {
    height: '60px',
    textAlign: 'center',
    margin: '-4px -20px',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <img src={LogoNavbar} alt="navbar-logo" className={classes.logoFont} />
  );
};

export default LogoFull;
