import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';


export const onepaasHelperPlugin = createPlugin({
  id: 'onepaas-helper',
  routes: {
    root: rootRouteRef,
  },
});
export const CatalogBuilderPage = onepaasHelperPlugin.provide(
  createRoutableExtension({
    name: 'CatalogBuilderPage',
    component: () =>
      import('./components/CatalogBuilder').then(
        m => m.CatalogBuilderComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const TemplateBuilderPage = onepaasHelperPlugin.provide(
  createRoutableExtension({
    name: 'TemplateBuilderPage',
    component: () =>
      import('./components/TemplateBuilder').then(
        m => m.TemplateBuilderComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
