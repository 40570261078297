import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const MTNTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#f1bf51', // Bright yellow for high visibility
        // main: '#f1bf51', // Bright yellow for high visibility
        dark: '#b28b00', // Darker shade for hover states
        light: '#ffe34f', // Lighter shade for accents
      },
      secondary: {
        main: '#565a6e', // Neutral secondary color for text and icons
        dark: '#3d4151',
        light: '#777a8b',
      },
      error: {
        main: '#a33f51', // Muted dark red for errors
        light: '#bf6371',
        dark: '#7b2d41',
      },
      warning: {
        main: '#b97d21', // Softer orange for warnings
        light: '#d99b48',
        dark: '#915b12',
      },
      info: {
        main: '#4a6c9e', // Calming blue for information
        light: '#6d89b5',
        dark: '#34548a',
      },
      success: {
        main: '#5c7c3e', // Muted green for success messages
        light: '#789956',
        dark: '#485e30',
      },
      banner: {
        info: '#4a6c9e',
        error: '#a33f51',
        text: '#343b58',
        link: '#565a6e',
      },
      background: {
        default: '#f7f7f9', // Slight off-white for a modern clean background
        paper: '#ffffff', // Paper-white for clear distinction in content
      },
      navigation: {
        background: '#333333', // Darker background for modern navigation
        indicator: '#f1bf51', // Primary yellow as an indicator for active states
        color: '#d5d6db', // Soft off-white for text in navigation
        selectedColor: '#FFFFFF', // Pure white for selected navigation items
      },
    },
  }),
  defaultPageTheme: 'home',
  // fontFamily: 'Segoe UI', 
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
    documentation: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
    tool: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
    service: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
    website: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
    library: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
    other: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
    app: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
    apis: genPageTheme({ colors: ['#f1bf51'], shape: shapes.wave2 ,options:{
      fontColor:'#333333'
    }}),
  },
  
});
