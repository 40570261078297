import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  TextField,
} from '@material-ui/core';
import PasswordValidator from 'password-validator';
import React, { useEffect, useState } from 'react';

export type LoginFormProps = {
  onSubmit: (username: string, password: string) => void;
  onSignInError?: (error: Error) => void;
  error?: Error;
  helperTextUsername?: string;
  helperTextPassword?: string;
  validateUsername?: (usr: string) => boolean;
  validatePassword?: (pass: string) => boolean;
  usernameLabel?: string;
  onLoading?: boolean;
};

const passwordSchema = new PasswordValidator();
const usernameSchema = new PasswordValidator();

passwordSchema.is().min(4).not().spaces();
usernameSchema.is().min(4).is().max(40).not().spaces();

export const LoginForm = ({
  onSubmit,
  onSignInError,
  error,
  helperTextUsername,
  helperTextPassword,
  validatePassword,
  validateUsername,
  usernameLabel,
  onLoading,
}: LoginFormProps) => {
  const validatePasswd =
    validatePassword || passwordSchema.validate.bind(passwordSchema);
  const validateUsern =
    validateUsername || usernameSchema.validate.bind(usernameSchema);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [uError, setUError] = useState(Boolean(error));
  const [pError, setPError] = useState(Boolean(error));

  function onClick() {
    if (!uError && !pError) onSubmit(username, password);
  }

  useEffect(() => {
    const isUsernameValid = validateUsern(username) as boolean;
    const isPasswordValid = validatePasswd(password) as boolean;
    setUError(!isUsernameValid);
    setPError(!isPasswordValid);
  }, [username, password]);

  useEffect(() => {
    if (error && onSignInError) {
      onSignInError(error);
    }
  }, [error, onSignInError]);

  useEffect(() => {
    const keyDownHandler = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onClick();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  return (
    <>
      <FormControl variant="outlined">
        <InputLabel
          shrink
          htmlFor="username"
          style={{
            fontSize: '1.2rem',
            margin: '0 -14px',
          }}
        >
          {usernameLabel || 'Username'}
        </InputLabel>
        <TextField
          required
          // label=
          onChange={e => setUsername(e.target.value)}
          value={username}
          id="username"
          error={uError}
          helperText={uError ?? helperTextUsername}
          fullWidth
          InputProps={{
            style: {
              padding: 5,
              marginTop: 20,
              borderRadius: 4,
              background: 'rgb(232, 240, 254)',
              fontSize: '1.2rem',
            },
          }}
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel
          shrink
          htmlFor="password"
          style={{
            fontSize: '1.2rem',
            margin: '0 -14px',
          }}
        >
          Password
        </InputLabel>
        <TextField
          required
          onChange={e => setPassword(e.target.value)}
          value={password}
          id="password"
          type="password"
          error={pError}
          helperText={pError ?? helperTextPassword}
          fullWidth
          InputProps={{
            style: {
              padding: 5,
              marginTop: 20,
              borderRadius: 4,
              background: 'rgb(232, 240, 254)',
              fontSize: '1.2rem',
            },
          }}
        />
      </FormControl>
      <Button
        endIcon={onLoading ? <CircularProgress size={17} /> : undefined}
        disabled={uError || pError}
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={onClick}
        type="submit"
        style={{
          marginTop: 16,
          // padding: '12px 0', // Larger button size
          // fontSize: '1.1rem', // Larger button text
        }}
      >
        Login
      </Button>
    </>
  );
};
